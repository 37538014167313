import React from 'react';

const AdditionalInformation = ({ additional_information }) => {
  return (
    <div className="additionalInformationnew">
      <p className="profile-description">{additional_information}</p>
      <div className="experience-bar"></div>
    </div>
  );
};

export default AdditionalInformation;
